@tailwind base;
@tailwind components;
@tailwind utilities;




@layer base {
  :root {
    --background: 0 0% 100%;
    /*hsl(0 0% 100%)*/
    --foreground: 222.2 84% 4.9%;
    /*hsl(222.2 84% 4.9%)*/

    --card: 0 0% 100%;
    /*hsl(0 0% 100%)*/
    --card-foreground: 222.2 84% 4.9%;
    /*hsl(222.2 84% 4.9%)*/

    --popover: 0 0% 100%;
    /*hsl(0 0% 100%)*/
    --popover-foreground: 222.2 84% 4.9%;
    /*hsl(222.2 84% 4.9%)*/

    --primary: 220, 87%, 51%;
    /*hsla(220, 87%, 51%)*/
    --primary-foreground: 210 40% 98%;
    /*hsl(210 40% 98%)*/

    --secondary: 221, 100%, 97%;
    /*hsl(221, 100%, 97%)*/
    --secondary-foreground: 220, 100%, 38%;
    /*hsl(220, 100%, 38%)*/

    --muted: 210 40% 96.1%;
    /*hsl(210 40% 96.1%)*/
    --muted-foreground: 215.4 16.3% 46.9%;
    /*hsl(215.4 16.3% 46.9%)*/

    --accent: 210 40% 96.1%;
    /*hsl(210 40% 96.1%)*/
    --accent-foreground: 222.2 47.4% 11.2%;
    /*hsl(222.2 47.4% 11.2%)*/

    --destructive: 4, 86%, 58%;
    /*hsl(4, 86%, 58%)*/
    --destructive-foreground: 210 40% 98%;
    /*hsl(210 40% 98%)*/

    --border: 214.3 31.8% 91.4%;
    /*hsl(214.3 31.8% 91.4%)*/
    --input: 214.3 31.8% 91.4%;
    /*hsl(214.3 31.8% 91.4%)*/

    --ring: 220, 87%, 51%;
    /*hsl(220, 87%, 51%)*/
    --radius: 10px;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

@layer utilities {}




/* ========= FullCalendarJS ========== */

.fc .fc-timegrid-col {
  border: 4px solid white;
}

td.fc-day.fc-day-sat,
td.fc-day.fc-day-sun {
  background: repeating-linear-gradient(-45deg,
      #F5F8FF,
      #F5F8FF 15px,
      #EFF4FF 5px,
      #EFF4FF 30px);
}

th.fc-col-header-cell.fc-day.fc-day-today a {
  background-color: #155EEF;
  border-radius: 4px;
  padding: 2px, 4px;
  color: white;
}

button.fc-button.fc-button-primary,
button.fc-button.fc-button-primary:not(:disabled):active:focus,
button.fc-button.fc-button-primary:active,
button.fc-button.fc-button-primary:focus {
  outline: none;
  box-shadow: none;
}

button.fc-today-button.fc-button.fc-button-primary {
  border-radius: 8px;
  padding: 6px, 16px;
  border: 1px solid #155EEF;
  text-transform: capitalize;
  min-width: 90px;
}

button.fc-newShift-button.fc-button.fc-button-primary:active,
button.fc-newShift-button.fc-button.fc-button-primary {
  min-width: 134px;
  background-color: #155EEF;
  border-radius: 8px;
  color: white;
  padding: 8px 10px;
  margin-left: 20px;
  border: none;
  font-size: 16px;
}

.fc-header-toolbar > .fc-toolbar-chunk:nth-child(1)::before {
  font-size: 1.5rem;
  line-height: 2rem;
  content: 'My Schedule';
  font-weight: 700;
  margin-left: 5px;
}

.fc-scrollgrid-sync-inner {
  margin-bottom: 4px;
}

:root {
  --fc-border-color: white;
  --fc-today-bg-color: #EFF4FF;

  --fc-button-text-color: #155EEF;
  --fc-button-bg-color: transparent;
  --fc-button-border-color: transparent;
  --fc-button-hover-bg-color: #F5F8FF;
  --fc-button-hover-border-color: #F5F8FF;
  --fc-button-active-bg-color: transparent;
  --fc-button-active-border-color: transparent;

  --fc-event-bg-color: transparent;
  --fc-event-border-color: transparent;
  --fc-event-text-color: #fff;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);
}